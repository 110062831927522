<!-- 评价 -->
<template>
    <div class="container">
        <div class="evaluation-header">
            <p class="font-16 font-bold">评价订单</p>
            <p class="font-12 color-999 mt-10">
                <span class="mr-20">订单号：<i class="color-333">{{order.ordersn}}</i></span>
                <span>{{order.createTime}}</span>
            </p>
            <p v-if="show" class="submit-result flex justify-c align-c">
                <i class="el-icon-circle-check"></i>
                <span>提交评价成功</span>
            </p>
        </div>
        <div class="evaluation-main flex">
            <div class="evaluation-main-l flex-column align-c">
                <div>
                    <el-image v-if="order.orderProducts" :src="order.orderProducts[0].thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 120px; height: 120px;">
                        <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 120px; height: 120px;">
                    </el-image>
                </div>
                <p v-if="order.orderProducts" class="mt-15 color-666">
                    <span>{{ order.orderProducts[0].productName}}</span>
                    <span v-if="order.orderProducts.length > 1">等<i class="color-orange">{{order.orderProducts.length}}种</i>商品</span>
                </p>
            </div>
            <div class="flex-1 color-666">
                <div class="flex align-c">
                    <p class="label">商品评分</p>
                    <p>
                        <el-rate v-model="value" :colors="['#ee0a24', '#ee0a24', '#ee0a24']"></el-rate>
                    </p>
                </div>
                <div class="flex mt-20">
                    <p class="label">评价晒单</p>
                    <p class="flex-1">
                        <el-form ref="content">
                            <el-form-item>
                                <el-input type="textarea" v-model="content" placeholder="评价商品，可以帮助更多想买的人"
                                    maxlength="200" show-word-limit :autosize="{ minRows: 4}" resize="none"></el-input>
                            </el-form-item>
                        </el-form> 
                    </p>
                </div>
                <div class="flex mt-10">
                    <p class="label"></p>
                    <div class="flex-1">
                        <p class="font-12 color-999 mb-10">只能上传jpeg、jpg、png 格式的图片，图片大小在5M以内</p>
                        <el-upload
                            ref="upload"
                            action=""
                            accept="image/jpeg,image/jpg,image/png"
                            list-type="picture-card"
                            name="file"
                            :on-remove="handleRemove"
                            :limit="6"
                            :file-list="fileList"
                            :http-request="uploadSectionFile">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                </div>
                <div class="flex">
                    <p class="label"></p>
                    <el-button class="submit-btn" @click="commentFn">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Evaluation',
    components: {},

    data() {
        return {
            value: 5,
            orderId: this.$route.query.id || '',
            order: {},
            content: '',
            fileList: [],
            imgList: [],
            show: false
        };
    },

    created() {
        this.getOrder()
    },

    methods: {
        getOrder() {
            this.$https.get(this.$api.orderInfo,{
                orderId: this.orderId,
                id: '',
                iskuaidi:'',
                shopId: '',
                number: Math.random()
            })
            .then((res) =>{
                this.order = res.data
            })
        },

        handleRemove(file, fileList) {
            let arr = this.imgList.filter((x) => x !== file.url)
            this.imgList = arr
            this.fileList = fileList
        },

        uploadSectionFile(params) {
            let file = params.file  //获取上传的文件
            let fileType = file.type   //获取文件类型
            let isImage = fileType.indexOf('image') != -1  // 判断是否是图片类型
            let isLt5M = file.size / 1024 / 1024 < 5
            let file_url = this.$refs.upload.uploadFiles[0].url
            if (!isLt5M) {  // 判断大小
                this.$message.error('上传的图片大小不能超过5M')
                this.$refs.upload.uploadFiles = []  //不符合就清空已选择的图片
                return
            }

            if(!isImage){  // 文件格式
                this.$message.error('请选择图片文件')
                this.$refs.upload.uploadFiles = []  //不符合就清空已选择的图片
                return
            }

            let img = new Image()
            img.src = file_url
            img.onload = () => {
                this.uploadFile(file)
            }
        },

        uploadFile(file) {
            let formData = new FormData()
            formData.append('file', file)
            this.$https.post(this.$api.upload, formData,{
                dataType: 'json'
            })
            .then((res) =>{
                this.imgList.push(res.key)
                this.fileList.push({name: res.key, url: res.key})
            })
        },

        commentFn() {
            if(!this.content){
                this.$msgbox.alert('请输入评价内容', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            } else if (this.imgList.length == 0){
                this.$msgbox.alert('至少上传一张图片', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                })
                return
            }

            this.$https.post(this.$api.addComment,{
                mid: this.order.mid,
                orderid: this.order.id,
                content: this.content,
                imageList: this.imgList,
                level: this.value
            })
            .then((res) =>{
                if(res.code == 0){
                    this.$message({
                        message: '评价成功',
                        type: 'success'
                    })
                    this.show = true
                }
                else {
                    this.$msgbox.alert(res.msg, '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                }
            })
        }
    }
}
</script>
<style lang='scss' scoped>
    .evaluation-header{
        padding: 25px 0 30px;
        text-align: center;
        .submit-result{
            margin-top: 20px;
            i{
                color: #14B359;
                font-size: 76px;
            }
            span{
                font-size: 24px;
                margin-left: 20px;
            }
        }
    }
    .evaluation-main{
        padding: 40px 90px 40px 0;
        background-color: #f5f5f5;
        .evaluation-main-l{
            width: 350px;
            padding: 0 50px;
            flex-shrink: 0;
            text-align: center;
        }
        .label{
            width: 120px;
            flex-shrink: 0;
        }
        .submit-btn{
            width: 200px;
            height: 48px;
            margin-top: 30px;
            padding: 12px 46px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            border: none;
            background-color: var(--orange-color);
        }
    }
</style>